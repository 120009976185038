import React from 'react';
import './legalNotice.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';

function LegalNotice() {
  return (
    <>
<Helmet>
    <title>Legal Notice | Stamens Software - A leading IT Company</title>
    </Helmet>

    <section className="custom-banner">
            <div className="container">
                <h1 className="banner-head text-center">
                    <span>Stamens Software</span>
                    Legal Notice
                </h1>
            </div>
        </section>

        <section className="legal-details">
            <div className="container">
                <div className="details-info">
                    <h2 className="h2">All Right Reserved</h2>
                    <p>All the content and information included on this website, such as: text, graphics, logos, button icons, images, video clips and software tools, are the proprietary property of Stamens Software Private Limited. The compilation of all the material contained on this website is the exclusive property of Stamens Software Private Limited, and therefore protected by International Copyright Laws.</p>
                    <p>Being a copyrighted material, it may not be used for any type of distribution, modification, reproduction or in parts without the prior written permissions of Stamens Software. All the images included on this website, may not be reproduced in any form without the prior advanced written consent of Stamens Software. If you believe any content appearing on our website constitutes a copyright infringement of other party's rights, please contact us immediately to notify us of the infringement.</p>

                    <h3 className="h2">Content Copyright</h3>
                    <p>All the content of this website is copyrighted to Stamens Software Pvt. Ltd. and should never be copied or reproduced to any other website without a written approval from the owner of Stamens Software Pvt. Ltd.</p>

                    <p>Websites you scrap content in the form of bots, manually or through RSS feeds will be penalized by reporting to their Web Host provider with an instant DMCA take down notice.</p>

                    <h4 className="h2">Electronic Communication</h4>
                    <p>Electronic communication basically refers to website visit and email communication. So, when you send us emails or visit our website, you are connecting with us electronically. With that you also consent to receive communications electronically from Stamens Software. Our mode of communication would be through emails and website notices. With that, you also provide your consent to all the agreements, notices, disclosures and other communications conducted electronically from Stamens Software to you, satisfy any legal requirement.</p>

                </div>
            </div>
        </section>
    <Footer />
    </>
  )
}

export default LegalNotice;