import React from 'react';
import { Link } from 'react-router-dom';

const routeMapping = {
    'About Us': '/about',
    'Awards': '/clients-testimonials',
    'Careers': '/career',
    'Charitable Initiatives': '/',
    'Events': '/gallery',
    'History': '/about',
    'Locations': '/Contact',
    'Newsroom': '/gallery',
    'Partners & Affiliations': '/clients-testimonials',
    'People and Culture': '/gallery',
    'Reseller Program': '/service',
    'Channel Partner Program': '/service',
    'BLOG': '/gallery',
    'CONTACT US': '/contact',
    'FEEDBACK': '/clients-testimonials',
    'LEGAL POLICY': '/legalnotice',
    'Privacy Policy': '/privacypolicy',
    'REFERRALS': '/contact',
    'Work With Us': '/',
    'Help & Support': '/contact',
    'Hire Talent': '/on-demand-developer',
    'Agriculture': '/',
    'Aviation': '/',
    'Banking': '/',
    'Casino Games': '/',
    'Construction': '/',
    'Corrections': '/',
    'E-Learning & Education': '/',
    'Energy & Utilities': '/',
    'Environmental Management': '/',
    'Finance': '/',
    'Food & Beverage': '/',
    'Gaming': '/',
    'Healthcare': '/',
    'Hospitality & Travel': '/',
    'Human Capital Management (HCM)': '/',
    'Legal': '/',
    'Lending': '/',
    'Media & Entertainment': '/',
    'Meetings & Events': '/',
    'Nonprofit': '/',
    'Oil & Gas': '/',
    'Payments': '/',
    'Political Campaigns': '/',
    'Real Estate': '/',
    'Retail': '/',
    'Sports': '/',
    'Supply Chain': '/',
    'Telecommunications': '/',
    'Transportation': '/',

    'Animation & Graphic Design': '/',
    'Artificial Intelligence': '/',
    'Asset Tracking (RFID & Barcode)': '/',
    'AR / VR': '/',
    'Autodesk': '/',
    'Blockchain': '/',
    'Business Intelligence & Analytics': '/',
    'Cloud': '/cloud-services',
    'CMS': '/service',
    'CRM': '/service',
    'Database': '/',
    'Digital Asset Management': '/',
    'Digital Marketing': '/digital-marketing-services',
    'Document Management': '/',
    'Embedded Software': '/',
    'ERP': '/',
    'Facilities Management': '/',
    'Field Service Management': '/',
    'Firmware': '/',
    'Franchise Management': '/',
    'Fraud Protection': '/',
    'GIS': '/',
    'IOT & M2M': '/',
    'IT Service Management': '/',
    'Legacy': '/',
    'Location Based Services': '/',
    'Middleware': '/',
    'Mobile & Tablet': '/',
    'Open Source': '/',
    'Oracle': '/',
    'POS / Payment Processing': '/',
    'Salesforce': '/',
    'Sales Management': '/',
    'SAP': '/',
    'Self-Service': '/',
    'Telephony': '/',
    'UI / UX': '/best-ui-ux-design-services',
    'UiPath': '/',
    'Wearable Technology': '/',
    'Web Development': '/web-development-services',

    'Custom Software Services': '/custom-software-development-company',
    'API Development': '/api-integration',
    'Application Development': '/mobile-app-development-services',
    'Application Maintenance': '/',
    'Backup & Disaster Recovery': '/',
    'Implementation & Deployment': '/implementation-and-deployment',
    'Infrastructure Support': '/',
    'Product Lifecycle Management (PLM)': '/',
    'Quality Assurance': '/',
    'Migrations & Upgrades': '/',
    'SLA Support': '/',
    'Software Security': '/',
    'Systems Integration': '/',
    'Web Hosting': '/',

    'Objective-C Developers': '/service',
    'Scala Developers': '/service',
    'C++ Developers': '/service',
    'HTML5 Developers': '/service',
    'PHP Developers': '/service',
    'Swift Developers': '/service',
    'Golang Developers': '/service',
    'Embedded C Developers': '/service',
    'XML Developers': '/service',
    'SQL Developers': '/service',
    'Python Developers': '/service',
    '.NET Developers': '/service',
    'Ruby On Rails Developers': '/service',
    'C/ Developers': '/service',
    'Java Developers': '/service',
    'Perl Developers': '/service',
    'JavaScript Developers': '/service',

    'Our Clients': '/clients-testimonials',
    'Our Work': '/service'
};

const siteMap = [
    {
        heading: 'COMPANY',
        list: ['About Us', 'Awards', 'Careers', 
               'Charitable Initiatives', 'Events', 'History', 
               'Locations', 'Newsroom','Partners & Affiliations', 
               'People and Culture', 'Reseller Program', 'Channel Partner Program',
               'BLOG', 'CONTACT US', 'FEEDBACK',
               'LEGAL POLICY', 'Privacy Policy', 'REFERRALS',
               'Work With Us', 'Help & Support', 'Hire Talent'
            ]
    },
    {
        heading: 'INDUSTRIES',
        list: ['Agriculture', 'Aviation', 'Banking',
               'Casino Games', 'Construction', 'Corrections',
               'E-Learning & Education', 'Energy & Utilities', 'Environmental Management',
               'Finance', 'Food & Beverage', 'Gaming',
               'Healthcare', 'Hospitality & Travel', 'Human Capital Management (HCM)',
               'Legal', 'Lending', 'Media & Entertainment',
               'Meetings & Events', 'Nonprofit', 'Oil & Gas',
               'Payments', 'Political Campaigns', 'Real Estate',
               'Retail', 'Sports', 'Supply Chain',
               'Telecommunications', 'Transportation'

        ]
    },
    {
        heading: 'SOLUTIONS',
        list: ['Animation & Graphic Design', 'Artificial Intelligence', 'Asset Tracking (RFID & Barcode)',
               'AR / VR', 'Autodesk', 'Blockchain',
               'Business Intelligence & Analytics', 'Cloud', 'CMS',
               'CRM', 'Database', 'Digital Asset Management',
               'Digital Marketing', 'Document Management', 'Embedded Software',
               'ERP', 'Facilities Management', 'Field Service Management',
               'Firmware', 'Franchise Management', 'Fraud Protection',
               'GIS', 'IOT & M2M', 'IT Service Management',
               'Legacy', 'Location Based Services', 'Middleware',
               'Mobile & Tablet', 'Open Source', 'Oracle',
               'POS / Payment Processing', 'Salesforce', 'Sales Management',
               'SAP', 'Self-Service', 'Telephony',
               'UI / UX', 'UiPath', 'Wearable Technology',
               'Web Development',

        ]
    },
    {
        heading: 'SERVICES',
        list: ['Custom Software Services', 'API Development', 'Application Development',
               'Application Maintenance', 'Backup & Disaster Recovery', 'Implementation & Deployment',
               'Infrastructure Support', 'Product Lifecycle Management (PLM)', 'Quality Assurance',
               'Migrations & Upgrades', 'SLA Support', 'Software Security',
               'Systems Integration', 'Web Hosting'

        ]
    },
    {
        heading: 'ON-DEMAND DEVELOPERS',
        list: ['Objective-C Developers', 'Scala Developers', 'C++ Developers',
               'HTML5 Developers', 'PHP Developers', 'Swift Developers',
               'Golang Developers', 'Embedded C Developers', 'XML Developers',
               'SQL Developers', 'Python Developers', '.NET Developers',
               'Ruby On Rails Developers', 'C/ Developers', 'Java Developers',
               'Perl Developers', 'JavaScript Developers'


        ]
    },
    {
        heading: 'PORTFOLIO',
        list: ['Our Clients', 'Our Work']
    }
]

function SiteMapComponent() {
  return (
    <section class="sitemap-sec bg-white">
    <div className='container'>
        {siteMap.map((item, index) => (
            <div key={index} className="site-map-section">
                <h2 className="head">{item.heading}</h2>
                <ul>
                {item.list.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <Link to={routeMapping[link] || '/'}>{link}</Link>
                </li>
              ))}
                </ul>
            </div>
        ))}
    </div>
</section>
  )
}

export default SiteMapComponent;
