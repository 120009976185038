import React from 'react';
import './cloudservices.css';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';
import { Link } from 'react-router-dom';

const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;
const devOps = `${process.env.PUBLIC_URL}/images/tools/dev-ops.png`;
const aws = `${process.env.PUBLIC_URL}/images/tools/aws.png`;
const azureOne = `${process.env.PUBLIC_URL}/images/tools/azure-1.png`;
const googleCloud = `${process.env.PUBLIC_URL}/images/tools/google-cloud.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const client = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const bannerDetails =
    {
        title: 'Our Services',
        subTitle: 'Cloud Services (DevOps, AWS, GCP, Azure)',
        description: 'Secure and managed cloud solutions that drive the outcome-based modernization you need.'
    }

    const serviceCard = [
        {
            heading: 'Cloud Consulting',
            para: "Leverage our decades of experience with some of the world's most complicated IT environments to create your optimal cloud strategy, streamline your IT architecture, and achieve your desire business results.",
        },
        {
            heading: 'Private Cloud Services',
            para: 'Visualize and refine your concept with our prototype designing services. By creating interactive prototypes, we help you test and validate key features, gather user feedback, and make informed decisions before investing in full-scale development.Experience the perfect combination of cloud efficiency and private infrastructure performance, compliance, and security that empowers you to modernize and manage your enterprise. We offer a unified, consistent approach across all the cloud landscape with our dedicated on-prem resources and our seamless integration with public cloud workloads.',
        },
        {
            heading: 'Public Cloud Services',
            para: 'Experience the perfect combination of cloud efficiency and private infrastructure performance, compliance, and security that empowers you to modernize and manage your enterprise. We offer a unified, consistent approach across all the cloud landscape with our dedicated on-prem resources and our seamless integration with public cloud workloads.',
        },
        {
            heading: 'Modern Operations',
            para: "Kyndryl seamlessly integrates people, processes, and cutting-edge technology to provide unparalleled value that iterates on your current state, anticipated future needs, and the precise steps required to optimize your operational processes and maximize efficiency. Our evolved operating model is specifically tailored to meet the challenges and opportunities of today's rapidly evolving digital landscape, and expertly managed to facilitate ongoing business growth.",
        },
        {
            heading: 'Cloud Migration Services',
            para: "Embark on your cloud adoption journey with Kyndryl's Cloud Migration Services. Develop and execute the optimal migration strategy for your enterprise. Minimize the complexities of cloud migration and streamline your journey with our expert assessment and seamless workload migration capabilities. Kyndryl builds secure, repeatable, and scalable cloud environments for you on the cloud provider of your choice.",
        },
        {
            heading: 'Managed Cloud Services',
            para: 'With a suite of unparalleled capabilities across public, private, and hybrid cloud, Kyndryl empowers enterprises to optimize and modernize their infrastructure and applications, enabling them to fully harness the potential of the cloud, while also offering an array of managed services that cover the entire IT stack. Maximize the full potential of your IT infrastructure with Kyndryl Managed Services.',
        },
    ]

    const serviceCardProcess =[
        {
            heading: 'Integration',
            para: "Using Cloud Services, we help you integrate with other business applications and services, such as customer relationship management (CRM) tools or accounting software, allowing businesses to streamline their operations and improve productivity.",
        },
        {
            heading: 'Automation',
            para: "We automate routine tasks, such as software updates, backups, and security patches, freeing up staff to focus on more strategic initiatives.",
        },
        {
            heading: 'Hybrid Cloud Solutions',
            para: "We specialize in developing hybrid cloud solutions, which combine public and private cloud infrastructure to provide the benefits of both. This approach provides greater flexibility, scalability, and cost savings, while also ensuring data security and regulatory compliance.",
        },
        {
            heading: 'Serverless Computing',
            para: "We use serverless computing technologies to develop cloud solutions that are highly scalable, cost-effective, and efficient. This approach eliminates the need for dedicated servers, allowing you to pay only for the resources you use and reducing your infrastructure costs.",
        },
        {
            heading: 'Blockchain Technology',
            para: "We also have expertise in blockchain technology, which we can integrate into our cloud solutions to provide secure, decentralized data storage and management. This approach provides greater security, transparency, and accountability, making it ideal for industries such as finance, healthcare, and logistics.",
        },
        {
            heading: 'Microservices Architecture',
            para: "We create Microservices architecture with a modern approach to design and deploy cloud-based applications as a collection of small, independently deployable services, each focused on a specific business function or capability. In a microservices architecture, applications are decomposed into loosely coupled services that communicate through lightweight protocols such as HTTP or messaging queues.",
        },
    ]

function CloudServices() {
  return (
    <>
   <Helmet>
    <title>Cloud Services | DevOps | AWS | GCP | Azure | Stamens Software-Services</title>
    </Helmet>
 
 <Banner details={bannerDetails}/>

 <section className="cloud-services bg-white">
            <div className="container">
                <h2 className="head text-center">
                    Our cloud services
                </h2>
                <p className="text-center fs-5 pb-4">With a focus on agile methodologies and user-centric design, our MVP Development Services provide strategic planning and execution to bring your vision to life. We turn ideas into viable products, ensuring your project’s success from inception to launch.</p>
                <div className="center-sec">
                    <div className="row justify-content-center gy-4">
                    {serviceCard.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={"col-lg-4 col-md-6"}/>
            ))}
                    </div>
                </div>
            </div>
        </section>

        <section className="technologies-cloud">
            <div className="container">
                <h3 className="head text-center">
                    Strategic Partnerships
                </h3>
                <p className="text-center fs-5 pb-4">We hold strategic partnerships with some of the leading industry players and global technology vendors</p>
                <div className="tools">
                    <div className="tool">
                        <div className="image">
                            <img src={devOps} alt="DevOps" className="img-fluid" />
                        </div>
                        <p>DevOps</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={aws} alt="AWS" className="img-fluid" />
                        </div>
                        <p>AWS</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={googleCloud} alt="GCP" className="img-fluid" />
                        </div>
                        <p>GCP</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={azureOne} alt="Azure" className="img-fluid" />
                        </div>
                        <p>Azure</p>
                    </div>
                </div>
            </div>
        </section>

     <LetsCode />

        <section className="process-cloud">
            <div className="container">
                <h3 className="head text-center">
                    What we do?
                </h3>
                <p className="text-center fs-5 pb-4">We have a tried-and-tested process in place for developing time-efficient MVPs tailored to your specific needs. You can trust us to deliver results that exceed your expectations. Let’s have a look at our MVP development process.</p>
                
                <div className="row justify-content-center gy-4">
                {serviceCardProcess.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={"col-lg-4 col-md-6"} childClassA={"head"} h4={'h4'}/>
            ))}
                </div>

                <div className="text-center">
                    <Link to="/contact" className="requirements">DISCUSS YOUR REQUIREMENTS</Link>
                </div>

            </div>
        </section>


        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={appMobile} alt="mvp development" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <Link to="/contact" className="hire-btn">Hire Us Now</Link>
                        </div>
                    </div>
                </div>
        </section>


        <section className="our-company-cloud">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

 <Footer />
    </>
  )
}

export default CloudServices;
