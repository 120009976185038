import React from "react";
import { Link, useLocation } from "react-router-dom";
import './breadcrumb.css';

const Breadcrumbs = () => {
  const location = useLocation();

  const ServicePaths = [
    'custom-software-development-company',
    'api-integration',
    'best-ui-ux-design-services',
    'mvp-development',
    'cloud-services',
    'web-development-services',
    'mobile-app-development-services',
    'e-commerce-development-services',
    'hire-dedicated-team',
    'implementation-and-deployment',
    'technical-it-support',
    'digital-marketing-services'
  ];

  const DigitalMarketingPaths = [
    'search-engine-optimization-services-india',
    'social-media-marketing-india',
    'google-ads-services-india'
  ];

  const pathnames = location.pathname.split("/").filter((x) => x);

  if (pathnames.length === 1 && ServicePaths.includes(pathnames[0])) {
    pathnames.unshift('service');
  } else if (pathnames.length === 1 && DigitalMarketingPaths.includes(pathnames[0])) {
    pathnames.unshift('service', 'digital-marketing-services');
  } else if (
    pathnames.length > 1 &&
    DigitalMarketingPaths.includes(pathnames[pathnames.length - 1])
  ) {
    pathnames.unshift('service');
    pathnames.splice(1, 0, 'digital-marketing-services');
  }

  const capitalizeFirstLetter = (str) => {
    return str.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  };

  return (
    <div className="breadcrumb">
      <ul vocab="http://schema.org/" typeof="BreadcrumbList">
        <li property="itemListElement" typeof="ListItem">
          <i className="icon-home"></i>
          <a property="item" typeof="WebPage" href="/">
            <span property="name">Home</span>
          </a>
          <meta property="position" content="1" />
        </li>

        {pathnames.map((value, index) => {
          const isLast = index === pathnames.length - 1;
          const position = index + 2;

          const path =
            value === 'digital-marketing-services'
              ? '/digital-marketing-services'
              : `/${pathnames.slice(0, index + 1).join("/")}`;

          return (
            <li
              key={index}
              property="itemListElement"
              typeof="ListItem"
              className={isLast ? "current" : ""}
            >
              {isLast ? (
                <span property="name">{capitalizeFirstLetter(value.replace(/-/g, " "))}</span>
              ) : (
                <Link property="item" typeof="WebPage" to={path}>
                  <span property="name">{capitalizeFirstLetter(value.replace(/-/g, " "))}</span>
                </Link>
              )}
              <meta property="position" content={position} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
