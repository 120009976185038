import React from 'react';
import { Link } from 'react-router-dom';

function CardComponent({cardProps, parentClass, childClassA,childClassB, dataAos, dataAosEasing, dataAosDuration, parentChildClass, buttonClass, paraClass}) {
    return (
        <div className={parentClass ? parentClass : 'col-lg-4 col-md-6'} data-aos={dataAos ? dataAos : null} data-aos-easing={dataAosEasing? dataAosEasing: null} data-aos-duration={dataAosDuration ? dataAosDuration : null}>
            <div className={parentChildClass ? parentChildClass : "choose-feature"} >
                <div className={childClassA? childClassA : "box-icon"}>
                    <img src={cardProps.image} alt={cardProps.alt? cardProps.alt : 'Icon'} className="img-fluid" />
                </div>
                <div className={childClassB ? childClassB : "choose-feature_content"}>
                    <h3 className="box-title">{cardProps.title}</h3>
                    <p className={paraClass || null ? paraClass : "choose-feature_text"}>{cardProps.description}</p>
                 {cardProps.button  ?  
                  <Link to={cardProps.to} className={buttonClass ? buttonClass : "readmore-btn"}>Read More</Link>
                 : null}   
                </div>
            </div>
        </div>
    );
}

export default CardComponent;
