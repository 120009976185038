import axios from 'axios';

export const apiRequest = async ({ url, method,  data, header}) => {
    
  try {
console.log("dfghjk",data);

    const response = await axios({
      url,
      method,
      headers: header ? header : {
        'Content-Type': 'application/json',
      },
      data, 
    });

    return response.data; 
  } catch (error) {

    throw new Error(error.response?.data?.message || error.message || 'An error occurred while making the API request.');
  }
};
