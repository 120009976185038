import React, { useEffect, useRef, useState } from 'react';
import './serviceform.css';

const ContactForm = `${process.env.PUBLIC_URL}/images/contact-form-side.png`;

function Serviceform({serviceformWeb}) {

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: ''
      });

      const [errors, setErrors] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: '',
        captcha: ''
      });

      
      const URL = `${process.env.REACT_APP_CONT_URL}`+'/api/Enquire'

      const formRef = useRef();

      const [captcha, setCaptcha] = useState('');
      const [captchaInput, setCaptchaInput] = useState('');
      const canvasRef = useRef(null);

      const generateCaptcha = () => {
        const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
      };

      const drawCaptcha = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = '#f0f0f0';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
    
          ctx.font = '30px Arial';
          ctx.fillStyle = '#000000';
          for (let i = 0; i < captcha.length; i++) {
            ctx.save();
            ctx.translate(30 * i + 10, Math.random() * 10 + 20);
            ctx.rotate(Math.random() * 0.2 - 0.1);
            ctx.fillText(captcha[i], 0, 0);
            ctx.restore();
          }
 
          for (let i = 0; i < 5; i++) {
            ctx.strokeStyle = '#cccccc';
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.stroke();
          }
        }
      };
    
      useEffect(() => {
        generateCaptcha();

        const handleHide = () => {
          setFormData({
            Name: '',
            Email: '',
            Phone: '',
            Message: ''
          });
          setCaptchaInput('');
          setErrors({});
          generateCaptcha();
        };
    
        const modalElement = document.getElementById('exampleModal');
        if (modalElement) {
          modalElement.addEventListener('hidden.bs.modal', handleHide);
        }
    
        return () => {
          if (modalElement) {
            modalElement.removeEventListener('hidden.bs.modal', handleHide);
          }
        };

      }, []);

      useEffect(() => {
        drawCaptcha();
      }, [captcha]);

      const validateName = (name) => {
        const nameRegex = /^[A-Za-z]+$/;
        return nameRegex.test(name);
      };

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        const phoneRegex = /^\+?[\d\s\-()]{7,15}$/;
        return phoneRegex.test(phone);
      };


      const handleChange =(e)=>{
        const { name, value } = e.target;
        if(name === 'captcha'){
          setCaptchaInput(value);
        }else {
        setFormData(prevState =>({
            ...prevState,
            [name] : value
        }));
      }
        
      }

      const handleSubmit =(e)=>{
        e.preventDefault();
        let valid = true;
        let newErrors = {}; 

        if(!formData.Name){
            valid = false;
            newErrors.Name = 'Name is required';
        }else if(!validateName(formData.Name)){
            valid = false;
            newErrors.Name = 'Only characters are allowed in the name!';
        }

        if(!formData.Email){
            valid = false;
            newErrors.Email = 'Email is required';
        }else if(!validateEmail(formData.Email)){
            valid = false;
            newErrors.Email = 'Not a valid mail';
        }
     
        if(!formData.Phone){
            valid = false;
            newErrors.Phone = 'Phone is required';
        }else if(!validatePhone(formData.Phone)){
            valid = false;
            newErrors.Phone = 'Not a number';
        }

        if(!captchaInput){
            valid = false;
            newErrors.captcha = 'Enter Captch';
          }else if(captcha !== captchaInput){
            valid = false;
            newErrors.captcha = 'CAPTCHA does not match';
            generateCaptcha();
          }

        if(valid){
       

        fetch(URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          })
          .then(response => {
            if (!response.ok) {
              
              throw new Error(`Failed to send email: ${response.statusText}`);
            }
            return response.json(); 
          })
          .then(() => {
            
            alert('Mail sent successfully');
            if (formRef.current) {
              formRef.current.reset(); 
            }
            setCaptchaInput(''); 
            setErrors({}); 
            generateCaptcha(); 
          })
          .catch(error => {
         
            alert('Failed to send email. Please try again later.');
          });

        setFormData({ 
            Name: '',
            Email: '',
            Phone: '',  
            Message: ''
          });
        setCaptchaInput('');
        generateCaptcha();
        setErrors({});
        }else{
            setErrors(newErrors);
        }
      }

    return (
        <section className={serviceformWeb ? serviceformWeb : "serviceform"}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h4 className="head">Let's Talk about everything!</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>
                    <div className="text-center">
                        <img src={ContactForm} alt="contact form side Image" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-6">
                    <form id="careerform" onSubmit={handleSubmit} ref={formRef} novalidate>
                        <div className="mb-2">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" id="name" name="Name" value={formData.Name} onChange={handleChange} />
                            {errors.Name && <small id="nameHelp" className="form-text text-danger">{errors.Name}</small>}
                        </div>
                        <div className="mb-2">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="text" className="form-control" name="Email" value={formData.Email} onChange={handleChange}/>
                            {errors.Email && <small id="emailHelp" className="form-text text-danger">{errors.Email}</small>}
                        </div>
                        <div className="mb-2">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input type="tel" className="form-control" id="phone" name="Phone" value={formData.Phone} onChange={handleChange}/>
                            {errors.Phone && <small id="phoneHelp" className="form-text text-danger">{errors.Phone}</small>}
                        </div>
                        <div className="mb-2">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea name="Message" id="Message" cols="30" rows="5" className="form-control" value={formData.Message} onChange={handleChange}></textarea>
                        </div>
                        <div className="col-md-12 my-3" id="captcha-container">
                            <div>
                                <label htmlFor="captcha" className="form-label mb-1">Enter Captcha:</label>
                                <canvas ref={canvasRef} width={200} height={40}></canvas>
                            </div>
                            <div>
                                <input type="text" className="form-control w-50" id="captcha-input" name="captcha" value={captchaInput}  onChange={handleChange} />
                                <button type="button" id="refreshCaptcha" className="btn btn-secondary" onClick={generateCaptcha} style={{marginLeft: '2%', height: '50%'}}>Refresh CAPTCHA</button>
                            </div>
                            {errors.captcha && <small id="captchaHelp" className="form-text text-danger py-2">{errors.captcha}</small>}
                        </div>
                        <div className="mb-2 mt-4 text-center">
                            <button type="submit" className="custom-btn" name="Submit" value="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
  )

}

export default Serviceform;
