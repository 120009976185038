import React, { useState } from 'react';
import './career.css';
import Footer from '../components/footer';
import CareerForm from '../components/CareerForm';
import { Helmet } from 'react-helmet';
import Consultant from '../components/consultant';
import Breadcrumbs from '../components/breadcrumb';

const careerSearchImg = `${process.env.PUBLIC_URL}/images/career-serach.png`;
const creativeIcon = `${process.env.PUBLIC_URL}/images/creative.svg`;
const politicsIcon = `${process.env.PUBLIC_URL}/images/politics.svg`;
const discussionIcon = `${process.env.PUBLIC_URL}/images/discussion.svg`;

function Career() {
    const [inputKeyword, setInputKeyword] = useState('');
    const [keyword, setKeyword] = useState('');
    const [location, setLocation] = useState('');
    const [profile, setProfile] = useState('');
    const [jobDetails, setJobDetails] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [showApplyForm, setShowApplyForm] = useState(false);
    const [applicationData, setApplicationData] = useState({
        name: '',
        email: '',
        phone: '',
        post: '',
        experience: 'fresher',
        about: '',
        cv: null,
        captcha: ''
    });

    const jobs = [
        { title: "Frontend Developer", location: "Noida", profile: "Developer", description: "Develop frontend applications using React.", requirements: "Experience in React, CSS, HTML, JavaScript.", salary: "$70,000 - $90,000" },
        { title: "Graphic Designer", location: "Noida", profile: "Designer", description: "Create stunning visuals for digital marketing.", requirements: "Proficiency in Adobe Creative Suite.", salary: "$50,000 - $70,000" },
        { title: "Project Manager", location: "Noida", profile: "Manager", description: "Manage team projects and resources.", requirements: "Experience in project management and team leadership.", salary: "$80,000 - $100,000" },
        { title: "Backend Developer", location: "Noida", profile: "Developer", description: "Develop backend APIs using Node.js.", requirements: "Strong Node.js and database skills.", salary: "$75,000 - $95,000" },
        { title: "UI/UX Designer", location: "Noida", profile: "Designer", description: "Design user interfaces and experiences.", requirements: "Knowledge of design tools and wireframing.", salary: "$60,000 - $80,000" }
    ];

    const filteredJobs = jobs.filter(job => {
        return (
            (!location || job.location === location) &&
            (!profile || job.profile === profile) &&
            (!keyword || job.title.toLowerCase().includes(keyword.toLowerCase()) || job.description.toLowerCase().includes(keyword.toLowerCase()))
        );
    });

    const handleJobClick = (job) => {
        setJobDetails(job);
        setShowDetails(true);
    };

    const handleBackToList = () => {
        setShowDetails(false);
        setJobDetails(null);
        setShowApplyForm(false);
        setApplicationData({
            name: '',
            email: '',
            phone: '',
            post: '',
            experience: 'fresher',
            about: '',
            cv: null,
            captcha: ''
        });
    };

    const handleSearchClick = () => {
        setKeyword(inputKeyword);
    };

    const handleApplyClick = (job) => {
        setJobDetails(job);
        setShowApplyForm(true);
    };

    const handleApplyClick2 = () =>{
        setShowApplyForm(true);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setApplicationData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setApplicationData(prevState => ({
            ...prevState,
            cv: e.target.files[0]
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

  return ( 
<>
<Helmet>
    <title>Career | Stamens Software - A leading IT Company</title>
    </Helmet>

<section className="custom-banner">
                <div className="container">
                    <h1 className="banner-head text-center">
                        <span>Career at Stamens</span>
                        An Opportunity to Learn & Create
                    </h1>
                    <h2 className="available-job"><span>{filteredJobs.length}</span> Jobs Available Now</h2>
                    <div className="banner-search">
                        <div className="back-sec">
                            <input 
                                type="text" 
                                placeholder="Search Keywords" 
                                value={inputKeyword}
                                onChange={(e) => setInputKeyword(e.target.value)}
                            />
                            <select value={location} onChange={(e) => setLocation(e.target.value)}>
                                <option value="">Select Location</option>
                                <option value="Noida">Noida, UP, India</option>
                                <option value="Faridabad">Faridabad, HR, India</option>
                                {/* <option value="Chicago">Chicago</option> */}
                            </select>
                            <button onClick={handleSearchClick}>
                                <i className="fa fa-magnifying-glass"></i> Search
                            </button>
                        </div>
                    </div>
                    <Breadcrumbs />
                </div>
            </section>

            <div className="container">
                <div className="filter">
                    <div className="name">Filter by</div>
                    <div className="location">
                        <label htmlFor="sidebarLocation">Location:</label>
                        <select value={location} onChange={(e) => setLocation(e.target.value)}>
                            <option value="">All Locations</option>
                            <option value="Noida">Noida, UP, India</option>
                                <option value="Faridabad">Faridabad, HR, India</option>
                            {/* <option value="Chicago">Chicago</option> */}
                        </select>
                    </div>
                    <div className="profile">
                        <label htmlFor="sidebarProfile">Profile:</label>
                        <select value={profile} onChange={(e) => setProfile(e.target.value)}>
                            <option value="">All Profiles</option>
                            <option value="Developer">Developer</option>
                            <option value="Designer">Designer</option>
                            <option value="Manager">Manager</option>
                        </select>
                    </div>
                </div>

                <div className="job-list">
                    {showDetails && !showApplyForm ? (
                        <div id="jobDetailsSection">
                            <div className="details">
                                <h3>{jobDetails.title}</h3>
                                <p><strong>Location:</strong> {jobDetails.location}</p>
                                <p><strong>Profile:</strong> {jobDetails.profile}</p>
                                <p><strong>Description:</strong> {jobDetails.description}</p>
                                <p><strong>Requirements:</strong> {jobDetails.requirements}</p>
                                <p><strong>Salary:</strong> {jobDetails.salary}</p>
                                <button className="btn btn-primary" style={{marginRight: '5px'}} onClick={handleApplyClick2}>Apply</button>
                                <button className="btn btn-primary" onClick={handleBackToList}>Back to Job List</button>
                            </div>
                        </div>
                    ) : showApplyForm ? (
                        <div className="apply-form" id='form'>
                            <div className="head">Apply for {jobDetails?.title}</div>
                            <CareerForm />
                            <button className="btn btn-primary mt-3" onClick={handleBackToList}>Back to Job List</button>
                        </div>
                    ) : (
                        filteredJobs.length > 0 ? (
                            filteredJobs.map((job, index) => (
                                <div key={index} className="job-item">
                                    <h4>{job.title}</h4>
                                    <p><strong>Location:</strong> {job.location}</p>
                                    <p><strong>Profile:</strong> {job.profile}</p>
                                    <p>{job.description}</p>
                                    <button className="btn btn-primary" onClick={() => handleJobClick(job)}>View More Details</button>
                                    <button className="btn btn-primary" style={{marginLeft: '5px'}} onClick={() => handleApplyClick(job)}>Apply</button>
                                </div>
                            ))
                        ) : (
                            <div className="no-results">
                                <p>No results found</p>
                            </div>
                        )
                    )}
                </div>
            </div>

<Consultant sectionClass={'consultation'} />

<section className="culture">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-5">
                        <div className="imgarea">
                            <img src={careerSearchImg} alt="career search" className="img-fluid rounded" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="content-part">
                            <h2 className="head">
                                <span>Work Culture</span>
                                We Provide Best Career Solutions
                            </h2>
                            <div className="part">
                                <img src={creativeIcon} height="100" width="100" alt="creative icon" className="img-fluid" />
                                <div>
                                    <h3>Creative</h3>
                                    <p>We always believe in Out of the Box thinking and it clearly shows in our Work Culture. Our workspace promotes young innovative thinking. “Think Quality & Think Creative”.</p>
                                </div>
                            </div>
                            <div className="part">
                                <img src={politicsIcon} height="100" width="100" alt="no politics icon" className="img-fluid" />
                                <div>
                                    <h3>No-Politics</h3>
                                    <p>At Stamens Software, we do not tolerate or promote any kind of politics, personal or professional. Come, work, learn and laugh with us, and benefit from the expert assistance available anytime for your queries.</p>
                                </div>
                            </div>
                            <div className="part">
                                <img src={discussionIcon} height="100" width="100" alt="discussion icon" className="img-fluid" />
                                <div>
                                    <h3>Discussion Oriented</h3>
                                    <p>Our work culture is based on positive coordination and discussion between young minds to bring out the best and innovational ideas. Healthy discussions are the means of sharing and building ideas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<Footer />
</>

  )
}

export default Career;