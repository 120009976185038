import React from 'react';
import Footer from '../components/footer';
import Banner from '../components/banner';
import './on-demand-developer.css';
import { Helmet } from 'react-helmet';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';

const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const affordable = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/affordable-and-highly-efficient-development-services.png`;
const distinctive = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/a-distinctive-engagement-model.png`;
const comprehensive = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/comprehensive-development-solutions.png`;

const dynamic = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/dynamic-on-demand-ai-solutions.png`;
const advanced = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/advanced-ai-on-demand-aiod-platform-solutions.png`;
const industry = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/industry-specific-ai-solutions.png`;
const tailored = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/tailored-ai-on-demand-services.png`;
const enterprise = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/enterprise-ai-solutions.png`;
const cloud = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/cloud-based-on-demand-ai-solutions.png`;

const objective = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/objective-c-developers.png`;
const scala = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/scala-developers.png`;
const cPlusPlus = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/c-plus-plus-developers.png`;
const html5 = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/html5-developers.png`;
const php = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/php-developers.png`;
const swift = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/swift-developers.png`;
const golang = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/golang-developers.png`;
const embedded = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/embedded-c-developers.png`;
const xml = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/xml-developers.png`;
const sql = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/sql-developers.png`;
const python = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/python-developers.png`;
const dotNet = `${process.env.PUBLIC_URL}/images/on-demand-dev-icon/dot-net-developers.png`;


const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'ON-DEMAND DEVELOPERS',
    description: "Looking for flexible, expert developers to meet your unique software development challenges? Our on-demand developers are ready to scale with your business, offering tailored solutions for projects of any size or complexity. Whether you're a startup needing an MVP or an enterprise aiming for robust digital transformation, we provide the expertise you need, when you need it."
}

const Productivity = [
  {
    image: affordable,
    heading: 'Affordable and Highly Efficient Development Services',
    para: 'Achieve exceptional productivity without worrying about hidden costs or overtime. Enjoy free QA and project management services while reducing your development expenses by 50-75%.',
  },
  {
    image: distinctive,
    heading: 'A Distinctive Engagement Model',
    para: 'Experience a refreshingly transparent approach with local interactions, real-time collaboration, and complete accountability. Our full-time, dedicated developers integrate seamlessly into your team for consistent, high-quality results.',
  },
  {
    image: comprehensive,
    heading: 'Comprehensive Development Solutions',
    para: 'With a team of 2,800 skilled in-house developers, we cover every technical requirement, bridging any skill gaps with industry-specific expertise. Whatever your needs, we’ve got you covered under one roof.',
  },
]

const AIDevelopers = [
  {
    image: dynamic,
    heading: 'Dynamic On-Demand AI Solutions',
    para: 'We deliver transformative on-demand AI solutions that redefine industries worldwide. From strategic AI consulting to robust solution architecture, we craft personalized strategies for impactful outcomes. Our services extend beyond implementation, including ongoing maintenance and support to ensure peak performance. Built on efficiency, reliability, and innovation, our custom AI solutions are designed to drive success and foster sustainable growth.',
  },
  {
    image: advanced,
    heading: 'Advanced AI-on-Demand (AIoD) Platform Solutions',
    para: 'Our AI-on-Demand (AIoD) platform enables businesses to seamlessly integrate AI into their operations with agility and speed. Offering advanced tools like predictive analytics, machine learning, and data visualization, the platform helps organizations harness the power of AI for actionable insights and streamlined processes. Our skilled on-demand developers ensure efficient integration, enabling businesses to stay ahead in the competitive tech landscape.',
  },
  {
    image: industry,
    heading: 'Industry-Specific AI Solutions',
    para: 'As a trusted leader in global software development, we specialize in delivering customized, industry-specific AI solutions. Our approach involves tailored AI consulting and integration services, aligning cutting-edge technology with the unique demands of your sector. From healthcare to finance, our solutions optimize operations, improve decision-making, and elevate customer experiences, driving measurable growth and innovation for your business.',
  },
  {
    image: tailored,
    heading: 'Industry-Specific AI Solutions',
    para: 'Our machine learning experts provide AI-on-Demand services tailored to your project goals. From advanced algorithm development to seamless AI model deployment, we deliver solutions that enhance productivity and optimize processes. Through strategic coding and integration, we align AI innovations with your organization’s objectives, helping you unlock smarter decision-making and long-term success.',
  },
  {
    image: enterprise,
    heading: 'Enterprise AI Solutions',
    para: 'Our enterprise AI solutions streamline operations and elevate organizational efficiency. Leveraging automation expertise, we develop intelligent systems, including AI-powered chatbots, that enhance customer interactions. With scalable cloud-based AI solutions, we ensure accessibility and growth for enterprises navigating the digital era. Our enterprise AI capabilities empower businesses to improve processes, deliver superior customer experiences, and maintain a competitive edge.',
  },
  {
    image: cloud,
    heading: 'Cloud-Based On-Demand AI Solutions',
    para: 'Our AI experts specialize in engineering cloud-based AI solutions that drive innovation and efficiency. By seamlessly integrating advanced AI technologies into your workflows, we help your business unlock new growth opportunities. With industry-specific expertise and tailored guidance, we enable organizations to capitalize on AI’s transformative potential, delivering insights and automation to achieve operational excellence.',
  },
]

const onDemand=[
  {
    image: objective,
    heading: 'Objective-C Developers',
    para: "Objective-C remains a reliable choice for developing iOS and macOS applications due to its flexibility, scalability, and robust capabilities. By leveraging Apple’s Cocoa and Cocoa Touch frameworks, our on-demand developers seamlessly blend existing Objective-C code with modern Swift, enabling the creation of versatile and high-performing applications. This integration combines the strengths of both languages, delivering innovative, feature-rich solutions tailored to your needs.",
  },
  {
    image: scala,
    heading: 'Scala Developers',
    para: "Scala is a robust language designed for scalability and ease of use, perfect for building highly performant systems. It blends functional and object-oriented programming paradigms to create efficient, reusable code. Our Scala developers leverage its capabilities to develop machine learning and AI-based software solutions that can process large datasets, helping you stay ahead of the curve in a data-driven world.",
  },
  {
    image: cPlusPlus,
    heading: 'C++ Developers',
    para: "C++ is ideal for developing performance-critical applications. With its low-level access to system memory and its efficient handling of complex data structures, C++ is the language of choice for high-performance systems like gaming engines, operating systems, and real-time applications. Our developers utilize C++ to deliver robust, scalable solutions that ensure maximum performance for demanding use cases.",
  },
  {
    image: html5,
    heading: 'HTML5 Developers',
    para: "HTML5 is the backbone of modern web development. Our HTML5 developers create responsive and mobile-first web applications, ensuring seamless user experiences across devices. From video integration to complex animations and graphics, HTML5 allows us to build dynamic and interactive websites that are both visually stunning and highly functional.",
  },
  {
    image: php,
    heading: 'PHP Developers',
    para: "PHP is a popular server-side language used to create dynamic websites and applications. Our PHP developers build powerful content management systems, APIs, and complex database-driven websites. We combine PHP’s flexibility with front-end technologies like HTML5 to create full-stack web solutions tailored to your business needs.",
  },
  {
    image: swift,
    heading: 'Swift Developers',
    para: 'Swift is a modern programming language designed by Apple for developing iOS and macOS applications. Our developers use Swift to build fast, efficient, and secure apps with a streamlined, expressive syntax. Swift’s ability to integrate with Objective-C allows for smoother transitions and enhanced functionality across different Apple devices.',
  },
  {
    image: golang,
    heading: 'Golang Developers',
    para: 'Golang (Go) is a fast, efficient language designed by Google to handle modern system-level software and cloud-based applications. With its ability to handle concurrent processes with ease, Go is ideal for scalable, high-performance applications. Our on-demand Golang developers create secure and reliable software solutions for the cloud and server-side applications.',
  },
  {
    image: embedded,
    heading: 'Embedded C Developers',
    para: 'Embedded C is used for developing firmware and software for embedded systems. Our developers specialize in creating custom solutions for microcontrollers, sensors, and other hardware-based systems. With our expertise in Embedded C, we deliver efficient, high-performance solutions for real-time systems and low-level programming.',
  },
  {
    image: xml,
    heading: 'XML Developers',
    para: 'XML is a versatile markup language used for storing and transferring data. Our developers use XML for data integration, schema validation, and creating interoperable applications. By leveraging XML with AJAX and other technologies, we build dynamic and scalable web applications that ensure seamless data exchange across different platforms.',
  },
  {
    image: sql,
    heading: 'SQL Developers',
    para: 'SQL is essential for managing relational databases. Our developers use SQL to design and optimize database systems, ensuring high performance and efficient data retrieval. We work with popular database platforms like MySQL, Microsoft SQL Server, and Oracle to develop robust solutions that help businesses manage large datasets and generate actionable insights.',
  },
  {
    image: python,
    heading: 'Python Developers',
    para: 'Python is a versatile and powerful language for developing scalable applications. Our Python developers specialize in building data-driven applications, machine learning solutions, and automation tools. With its simple syntax and portability, Python enables us to develop solutions for a wide range of industries quickly and efficiently.',
  },
  {
    image: dotNet,
    heading: '.NET Developers',
    para: '.NET is a powerful framework that supports cross-platform development. Our .NET developers use this framework to build secure, scalable, and high-performance applications. From enterprise-level applications to web services and APIs, we leverage .NET’s capabilities to deliver comprehensive software solutions tailored to your needs.',
  },
]

function OnDemandDeveloper() {
  return (
 <>
 <Helmet>
    <title>on-demand-developers | Development | Stamens Software-Services</title>
    </Helmet>

 <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

 <section className="developer">
            <div className="container">
                <h2 className="head text-center mb-5">Cost-Effective Solutions with Exceptional Productivity</h2>
                <div className="row justify-content-center gy-4">
                {Productivity.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}            
                </div>
            </div>
        </section>

        <section className="growth">
            <div className="container">
                <h2 className="head text-center">
                    Accelerate Growth and Innovation with On-Demand AI Developers
                </h2>
                <p className="text-center fs-5 pb-4">Our AI developers leverage advanced technologies to craft personalized AI solutions that align with your specific requirements. Our on-demand development team delivers efficient, innovative results, equipping your business with the tools needed to thrive in today’s digital landscape.</p>
                <div className="row justify-content-center gy-4">
                {AIDevelopers.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}     
                </div>
            </div>
        </section>

        <section className="hire-dev">
            <div className="container">
                <h3 className="head text-center pb-4">
                    Hire Highly Skilled Developers On-Demand
                </h3>
                <div className="row justify-content-center gy-4">
                {onDemand.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}     
                </div>
            </div>
        </section>

        <LetsCode />

        <section className="our-company-onDemand">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p><p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                    </div>
                </div>
            </div>
        </section>

 <Footer />
 </>
  )
}

export default OnDemandDeveloper;
