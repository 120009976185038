import React from 'react'

function CallFormButton() {

  return (
    <button type="button" className="schedule-call-btn" data-bs-toggle="modal" data-bs-target="#exampleModalcall">
    Schedule a Call
    </button>
  )
}

export default CallFormButton;