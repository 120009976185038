import React from 'react'
import { Link } from 'react-router-dom'

export default function Consultant({sectionClass}) {
  return (
    <section className={sectionClass}>
            <div className="container">
                <h4 className="head text-center">
                    <span>CONTACT US</span>
                    Get a Free Consultation
                </h4>
                <div className="row justify-content-center mt-5 gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-globe"></i>
                            </div>
                            <div>
                                <p>B-33, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div>
                                <a to="tel:+911203500848">+91 120 3500 848</a><br/>
                                <a to="tel:+911203500847">+91 120 3500 847</a>
                                <p>MON - FRI 10AM - 7PM</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div>
                                <Link to="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</Link>
                                <p>24 X 7 Online Support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}
