import React from 'react';
import './on-page-seo.css';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';

const seoanalysis = `${process.env.PUBLIC_URL}/images/on-page-seo/on-page-seo-analysis.png`;
const content = `${process.env.PUBLIC_URL}/images/on-page-seo/smart-high-quality-content.png`;
const Expertise = `${process.env.PUBLIC_URL}/images/on-page-seo/expertise-in-on-page-seo.png`;

const Audit = `${process.env.PUBLIC_URL}/images/on-page-seo/comprehensive-website-audit.png`;
const Keyword = `${process.env.PUBLIC_URL}/images/on-page-seo/keyword-research-and-implementation.png`;
const Engaging = `${process.env.PUBLIC_URL}/images/on-page-seo/smart-high-quality-content.png`;
const Enhancements = `${process.env.PUBLIC_URL}/images/on-page-seo/on-page-seo-analysis.png`;
const Linking = `${process.env.PUBLIC_URL}/images/on-page-seo/internal-linking-and-navigation.png`;
const Strategies = `${process.env.PUBLIC_URL}/images/on-page-seo/customized-strategies-for-business-goals.png`;
const Tracking = `${process.env.PUBLIC_URL}/images/on-page-seo/expertise-in-on-page-seo.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'On Page SEO with Stamens Software',
    description: "Our On-page SEO services are designed to deliver real business growth. Beyond improving rankings and boosting traffic, we focus on increasing leads and sales. We’ll collaborate with you to consult, implement, and oversee your entire search strategy, becoming true partners in driving your success."
}

const Productivity = [
    {
      image: seoanalysis,
      heading: 'Comprehensive On-Page SEO Analysis',
      para: 'We meticulously examine every aspect of your web pages to identify elements that may not be SEO-friendly, whether it’s your page content, meta tags, or title tags. This thorough approach ensures optimization at every level, leaving no detail overlooked that could influence your rankings.',
    },
    {
      image: content,
      heading: 'Smart, High-Quality Content',
      para: 'Effective on-page SEO relies on the strategic placement of keywords, much like off-page strategies. Our skilled content team crafts concise, engaging copy that integrates keywords seamlessly while maintaining readability and appeal.',
    },
    {
      image: Expertise,
      heading: 'Expertise in On-Page SEO',
      para: 'With extensive experience in optimizing web pages for Enterprise SEO and Ecommerce SEO projects, we have delivered outstanding results for our clients. Our proven on-page optimization techniques are designed to drive significant improvements in search performance.',
    },
  ]

  const Optimization = [
    {
      image: Audit,
      heading: 'Comprehensive Website Audit',
      para: 'We conduct an in-depth analysis of your web pages to uncover SEO-related issues, including missing or poorly structured meta tags, duplicate content, and broken links, ensuring no detail is overlooked.',
    },
    {
      image: Keyword,
      heading: 'Keyword Research and Implementation',
      para: 'Our team identifies the most relevant keywords for your business and seamlessly integrates them into your content, meta descriptions, title tags, and headings to maximize search visibility.',
    },
    {
      image: Engaging,
      heading: 'Engaging, Optimized Content',
      para: 'We create high-quality, keyword-driven content that is informative, user-friendly, and designed to improve rankings while engaging your target audience.',
    },
    {
      image: Enhancements,
      heading: 'Technical SEO Enhancements',
      para: "From optimizing page speed and improving mobile responsiveness to ensuring proper indexing and schema markup implementation, we address the technical aspects that affect your website's performance.",
    },
    {
      image: Linking,
      heading: 'Internal Linking and Navigation',
      para: 'We refine internal linking structures and enhance website navigation to improve user experience, distribute link equity effectively, and help search engines crawl your site more efficiently.',
    },
    {
      image: Strategies,
      heading: 'Customized Strategies for Business Goals',
      para: 'Our on-page SEO strategies are tailored to align with your unique business objectives, whether it’s boosting local visibility, enhancing ecommerce performance, or driving enterprise-level results.',
    },
  ]

  const seoSpecialists = [
    {
      image: Strategies,
      heading: 'Customized SEO Strategies',
      para: 'Our specialists tailor SEO plans to align with your business objectives, industry, and target audience, ensuring maximum impact.',
    },
    {
      image: Keyword,
      heading: 'Comprehensive Keyword Research',
      para: 'We identify high-performing keywords to optimize your content and improve search engine rankings for relevant searches.',
    },
    {
      image: Enhancements,
      heading: 'Technical SEO Expertise',
      para: "From improving site speed and fixing crawl errors to implementing schema markup, we address all technical aspects to enhance your website's performance.",
    },
    {
      image: Engaging,
      heading: 'Content Optimization',
      para: 'Our team ensures your content is engaging, keyword-rich, and aligned with search intent, driving better rankings and audience engagement.',
    },
    {
      image: Tracking,
      heading: 'Performance Tracking & Reporting',
      para: 'We provide regular updates and insights, tracking the success of your campaigns and adjusting strategies for optimal results.',
    },
    {
      image: Audit,
      heading: 'Proven Track Record of Success',
      para: 'With a history of delivering exceptional results across industries, our SEO specialists are equipped to help your business grow online.',
    },
  ]

function OnPageSeo() {
  return (
    <>

<Helmet>
<title>on-Page-Seo-Services | Development | Stamens Software-Services</title>
</Helmet>

<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

<section className="developer">
            <div className="container">
                <h2 className="head text-center mb-5">Why Choose Stamens Software for On Page Optimization?</h2>
                <div className="row justify-content-center gy-4">
                {Productivity.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}            
                </div>
            </div>
        </section>

<section className="growth">
        <div className="container">
            <h2 className="head text-center">
            Our Approach to On-Page SEO Optimization
            </h2>
            <p className="text-center fs-5 pb-4">Our approach to on-page SEO is designed to boost your website’s visibility, user engagement, and search rankings. From comprehensive audits and keyword-focused content to technical fine-tuning and tailored strategies, we ensure your site meets the highest SEO standards. With a proven track record of success, we help businesses achieve their goals by delivering measurable results and creating a seamless user experience that drives growth.</p>
            <div className="row justify-content-center gy-4">
            {Optimization.map((card, index) => (
           <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={"bg-white shadow rounded p-4 h-100"} h4={'h4'} childClassA={'h4'}/>
        ))}     
            </div>
        </div>
    </section>

    <section className="hire-dev">
            <div className="container">
                <h3 className="head text-center pb-4">
                    Hire Highly Skilled SEO Specialists
                </h3>
                <p className="text-center fs-5 pb-4">Elevate your online presence and drive measurable results by partnering with highly skilled SEO specialists. Our experts bring years of experience and a data-driven approach to help your business succeed in the digital landscape.</p>
                <div className="row justify-content-center gy-4">
                {seoSpecialists.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}     
                </div>
            </div>
        </section>

<LetsCode />

<section className="our-company-onpageseo">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={clients} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>    

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head">Partner with Our Client-Centered Team for Exceptional On-Page SEO Solutions </h3>
                        <p>We specialize in providing tailored On-Page SEO services to optimize your website’s visibility and performance. Our experienced team uses the latest tools and techniques to ensure that your website ranks higher, engages users, and drives quality traffic.Our On-Page SEO services include thorough optimization of key elements that affect search engine rankings and user experience. From keyword research and metadata optimization to enhancing page load speed and content structure, we ensure that every aspect of your website is optimized for success.</p>
                    </div>
                </div>
            </div>
        </section>

<Footer />
    </>
  )
}

export default OnPageSeo;
