import React from 'react';
import './privacyPolicy.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
  return (
   <>

<Helmet>
    <title>Privacy Policy | Stamens Software - A leading IT Company</title>
    </Helmet>

<section className="custom-banner">
            <div className="container">
                <h1 className="banner-head text-center">
                    <span>Stamens Software</span>
                    Privacy Policy
                </h1>
            </div>
        </section>

        <section className="privacy-details">
            <div className="container">
                <div className="details-info">
                    <h2 className="h2">Our contact details</h2>
                    <p>Name: Stamens Software Pvt Ltd</p>
                    <p>Address: B-31, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                    <p>Telephone: +91 120 3500 847 , +91 120 3500 848</p>
                    <p>Email: contact@stamenssoftware.com</p>

                    <h3 className="h2">This website is owned and operated by Stamens Software Pvt Ltd.</h3>
                    <p>All the users are, hereby, encouraged to read the Privacy Policy before visiting our website. Here at Stamens Software, we understand the privacy of our visitors and customers who come to our website, www.arysontechnologies.com. The main objective for creating this privacy policy is to demonstrate our firm commitment towards users' privacy. According to this policy, we respect the privacy of our visitors who purchase and utilize our software applications and other services respectively, which are offered on Stamens Software™ website.</p>

                    <p>At our Stamens Software website, we do not ask the personal information from the individuals who visit our websites unless they provide it to us voluntarily. There is no requirement for any user to register or provide any information to view or visit our website. The most appropriate measures are carried out to protect our users against any loss, misuse or any type of unexpected or unwanted alteration of the information that is received by them.</p>
                        
                    <p>During the purchase process, we do ask for certain information and all those information's are collected only for defined purposes. We at Stamens Software do not sell, rent or distribute any of your information's to any third party or firm. We use the information that you share only to send information related to our software solutions, services, updates and for sending you important updates. Stamens Software never sell or distribute user's information to any firm or third party.</p>

                    <h4 className="h2">All the information which are collected from our customers, are used for the following purposes:</h4>
                    <div className="row justify-content-center gy-4 mb-4">
                        <div className="col-md-4">
                            <div className="part">
                                <h5>Technical Support</h5>
                                <p>We can also use your email address to respond to your question which you submit to our Technical Support section. Our technical support team will use the email ID for making replies to your question.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="part">
                                <h5>Email Message</h5>
                                <p>You will receive email messages which will let you know the recent changes or updates at our website, new releases, promotional offers, special discounts, etc. if you submit your permission through our newsletter desk. You may also avoid these email messages as there is a removing option with every email newsletter.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="part">
                                <h5>Security</h5>
                                <p>Stamens Software always follow the best practices to safeguard you from any mishap by ensuring safe online transactions and by safeguarding your Credit card information. However, for any breach in security Stamens Software will never be held responsible.</p>
                            </div>
                        </div>
                    </div>

                    <h4 className="h2">What is the scope of this notice?</h4>
                    <p>This Privacy Notice (the “Privacy Notice”) explains how we collect, use, share, and protect your personal information (“Information”). This information shall be used in accordance with the permissions granted by you and in accordance with the UK GDPR, the EU General Data Protection Regulation (GDPR), and the UK Data Protection Act 2018.  We will also seek to comply with other relevant national data protection legislation where appropriate. If we update this policy, we will post any changes on our website. This Privacy Notice is also intended to assist you in making informed decisions when using our websites and products. Please take a minute to read and understand it and please note that it should be read in conjunction with our General Terms and Conditions and our Cookie Notice.</p>
                    <p>Stamens does not accept responsibility or liability for the content, tools, or privacy policies of external websites to which we may link. Please check the terms and conditions and privacy notices of such third parties before using, and providing any information to, third parties, their sites, and programs.</p>

                    <p>This notice applies to the website or portal on which it is posted. It also applies to interactions via social media channels, such as Facebook™, Twitter™, LinkedIn™ and YouTube™.</p>

                </div>
            </div>
        </section>

   <Footer />
   </>
  )
}
