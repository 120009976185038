import React from 'react';
import './it-infrastructure.css';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';

const implementation = `${process.env.PUBLIC_URL}/images/it-infrastructure/implementation.png`;
const performance = `${process.env.PUBLIC_URL}/images/it-infrastructure/performance.png`;
const storage = `${process.env.PUBLIC_URL}/images/it-infrastructure/storage-disaster-recovery.png`;
const cloudSupport = `${process.env.PUBLIC_URL}/images/it-infrastructure/cloud-support-services.png`;
const serverSupport = `${process.env.PUBLIC_URL}/images/it-infrastructure/server-support-services.png`;
const networkSupport = `${process.env.PUBLIC_URL}/images/it-infrastructure/network-support-services.png`;

const fileSystems = `${process.env.PUBLIC_URL}/images/it-infrastructure/file-systems-and-directories.png`;
const operatingSystems = `${process.env.PUBLIC_URL}/images/it-infrastructure/operating-systems-and-software.png`;
const firewallSolutions = `${process.env.PUBLIC_URL}/images/it-infrastructure/firewall-solutions.png`;

const application = `${process.env.PUBLIC_URL}/images/it-infrastructure/application-infrastructure-support.png`;
const website = `${process.env.PUBLIC_URL}/images/it-infrastructure/website-support.png`;
const enterprise = `${process.env.PUBLIC_URL}/images/it-infrastructure/enterprise-infrastructure-support.png`;
const smb = `${process.env.PUBLIC_URL}/images/it-infrastructure/smb-it-support.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const client = [
  `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
  `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
  `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
];

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'IT INFRASTRUCTURE SUPPORT SERVICES'
}

const Support = [
  {
    image: implementation,
    heading: 'Design & Implementation',
    para: 'Our database architects possess deep expertise in aligning business needs with infrastructure management. They implement best practices and standards to optimize data flows and ensure system efficiency.',
  },
  {
    image: performance,
    heading: 'Performance Optimization',
    para: 'We conduct in-depth database performance analysis to identify bottlenecks, optimize SQL queries, and implement prioritized solutions for tangible improvements.',
  },
  {
    image: storage,
    heading: 'Storage Disaster Recovery',
    para: 'Our team deploys robust data backup and disaster recovery solutions, offering scalable and reliable safeguards to protect your critical systems and ensure continuity.',
  },
  {
    image: cloudSupport,
    heading: 'Cloud Support Services',
    para: 'We secure, manage, and optimize your cloud infrastructures to enhance business alignment, improve operational efficiency, and refine processes.',
  },
  {
    image: serverSupport,
    heading: 'Server Support Services',
    para: "Our server specialists handle installations, configurations, upgrades, and other management tasks, enhancing your servers' performance, reliability, and uptime.",
  },
  {
    image: networkSupport,
    heading: 'Network Support Services',
    para: 'We provide comprehensive network design and architectural planning, perform audits, and deliver ongoing support for TCP/IP setups, VPSs, SANs, LAN/WAN integrations, and network device troubleshooting to maintain optimal connectivity and performance.',
  },
]

const ItOperations = [
  {
    image: fileSystems,
    heading: 'File Systems & Directories',
    para: 'We safeguard your data with encryption, maintain PCI-DSS compliance, monitor file integrity, and conduct thorough server scans to detect viruses, backdoors, rootkits, and local exploits.',
  },
  {
    image: operatingSystems,
    heading: 'Operating Systems & Software',
    para: 'Our team ensures your operating systems, third-party integrations, and software are consistently updated and maintained to prevent vulnerabilities caused by outdated systems.',
  },
  {
    image: firewallSolutions,
    heading: 'Firewall, IDS, & IPS Solutions',
    para: "We protect your infrastructure with advanced software and hardware firewalls, network-based Intrusion Detection Systems (IDS), and Intrusion Prevention Systems (IPS). Regular audits and vulnerability scans further enhance your system's security.",
  }
]

const ItInfra = [
  {
    image: fileSystems,
    heading: 'Application Infrastructure Support',
    para: 'We safeguard your data with encryption, maintain PCI-DSS compliance, monitor file integrity, and conduct thorough server scans to detect viruses, backdoors, rootkits, and local exploits.',
  },
  {
    image: operatingSystems,
    heading: 'Website & E-Commerce IT Support',
    para: 'Our team ensures your operating systems, third-party integrations, and software are consistently updated and maintained to prevent vulnerabilities caused by outdated systems.',
  },
  {
    image: firewallSolutions,
    heading: 'Enterprise Infrastructure Support',
    para: "We design custom enterprise IT infrastructure solutions to streamline user management, access controls, security protocols, email and VoIP systems, large-scale data migrations, and more.",
  },
  {
    image: firewallSolutions,
    heading: 'SMB IT Support',
    para: "Our tailored IT infrastructure services for small to midsize businesses enhance communication and collaboration through efficient data sharing, document management, and video conferencing capabilities.",
  }
]

function ItInfrastructure() {
  return (
    <>

<Helmet>
<title>IT Infrastructure | Stamens Software-Services</title>
</Helmet>

<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

<section class="our-fetaures-itinfrastructure">
            <div class="container">
                <h2 class="head">Premier IT Infrastructure Support Services</h2>
                <p class="tagline">Stamens delivers expert outsourced IT infrastructure support, modernizing platforms and technologies to enhance agility and scalability. Our top-tier infrastructure management services ensure your systems operate efficiently and stay future-ready.</p>
                <div class="row gy-4">
                {Support.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

        <section class="work-process-itinfrastructure">
            <div class="container">
                <h3 class="head text-center">Enhance and Secure Your IT Operations with Stamens</h3>
                <p class="tagline text-center">Stamens transforms and safeguards your IT operations by implementing cutting-edge technologies, ensuring your business systems remain modern, secure, and efficient.</p>
                <div class="row gy-4">
                {ItOperations.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-4'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

      <LetsCode />

      <section class="application-solutions-itinfrastructure">
            <div class="container">
                <h3 class="head">IT Infrastructure Integration Support Services</h3>
                <p class="tagline">We provide seamless integration support for IT infrastructure, ensuring efficient connectivity between systems, optimized workflows, and enhanced operational performance.</p>
                <div class="row gy-4">
                {ItInfra.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

        <section class="our-company-itinfrastructure">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={industry15} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={sourceCode} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={microsoftAzure} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={response24} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={certifiedDevelopers} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={projectManager} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={clients} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={tightDeadlines} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section class="app-mobile-itinfrastructure">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="col-md-4">
                        <img src={appMobile} alt="app-mobile" class="img-fluid" />
                    </div>
                    <div class="col-md-8">
                        <h3 class="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>  
                        <Link to="/contact" class="hire-btn">Hire Us Now</Link>          
                    </div>
                </div>
            </div>
        </section>

        <section class="trusted-datamigration">
            <div class="container">
                <div class="text-center">
                    <h4 class="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p class="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
                </div>
                <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {client.map((client, index) => (
                <SwiperSlide key={index} className='swiper-slide'>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
            </div>
        </section>  

<Footer />
    </>
  )
}

export default ItInfrastructure;
