import React from 'react';
import './datamigration.css'
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination } from 'swiper/modules';

const softwareMigration = `${process.env.PUBLIC_URL}/images/data-migration/software-migration-support.png`;
const websiteMigration = `${process.env.PUBLIC_URL}/images/data-migration/website-migration-services.png`;
const cloudMigration = `${process.env.PUBLIC_URL}/images/data-migration/cloud-migration-services.png`;
const databaseMigration = `${process.env.PUBLIC_URL}/images/data-migration/database-migration-services.png`;
const apiIntegration = `${process.env.PUBLIC_URL}/images/data-migration/api-integration-services.png`;
const enterpriseApplication = `${process.env.PUBLIC_URL}/images/data-migration/enterprise-application-integration.png`;
const applicationMigration = `${process.env.PUBLIC_URL}/images/data-migration/application-migration-solutions.png`;
const storageMigration = `${process.env.PUBLIC_URL}/images/data-migration/storage-migration-services.png`;

const dataMapping = `${process.env.PUBLIC_URL}/images/data-migration/ai-data-mapping-and-assessment.png`;
const anomalyDetection = `${process.env.PUBLIC_URL}/images/data-migration/ai-anomaly-detection.png`;
const monitoring = `${process.env.PUBLIC_URL}/images/data-migration/real-time-monitoring-and-reporting.png`;
const dataValidation = `${process.env.PUBLIC_URL}/images/data-migration/automated-data-validation.png`;

const preMigration = `${process.env.PUBLIC_URL}/images/data-migration/pre-migration-assessment.png`;
const postMigration = `${process.env.PUBLIC_URL}/images/data-migration/post-migration-validation-and-testing.png`;
const migrationTool = `${process.env.PUBLIC_URL}/images/data-migration/migration-tool-setup-and-configuration.png`;
const transformationDesign = `${process.env.PUBLIC_URL}/images/data-migration/data-transformation-design-and-debugging.png`;
const dataLoading = `${process.env.PUBLIC_URL}/images/data-migration/data-loading-support.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const client = [
    `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
    `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
    `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
  ];

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'DATA MIGRATION SERVICES'
}

const MigrationExperts = [
    {
      image: softwareMigration,
      heading: 'Software Migration Support',
      para: 'We employ meticulous, low-risk migration methodologies for both manual and automated processes, including data extraction, cleansing, loading, verification, testing, and implementation. Our approach prioritizes identifying potential challenges, creating contingency plans, and rigorously testing migrations in non-production environments to ensure flawless execution in live systems.',
    },
    {
      image: websiteMigration,
      heading: 'Website Migration Services',
      para: 'Our expertise in website migration spans a variety of hosting platforms, such as Bluehost, HostGator, Ionos, and GoDaddy, as well as website builders like WordPress, Wix, and SquareSpace. We manage all essential aspects of migration, including content, domain, platform, server, and SEO, ensuring a smooth transition that meets your specific requirements.',
    },
    {
      image: cloudMigration,
      heading: 'Cloud Migration Services',
      para: "We excel in facilitating seamless transitions to cloud environments, whether it's multi-cloud setups or migrations from on-premises servers. Partnering with top providers like Microsoft Azure, AWS, IBM Cloud, Oracle Cloud, Google Cloud Platform, and Alibaba Cloud, our services unlock benefits like improved scalability, flexibility, and cost-efficiency compared to traditional infrastructure.",
    },
    {
      image: databaseMigration,
      heading: 'Database Migration Services',
      para: 'Our database migration solutions encompass diverse data types and business logic schemas, utilizing Extract-Transform-Load (ETL) best practices, data mapping, machine learning, and control protocols. By leveraging predictive analytics, we ensure data quality, minimize errors, and maintain seamless user access without altering paths or links.',
    },
    {
      image: apiIntegration,
      heading: 'API Integration Services',
      para: 'We streamline API integration across various platforms to enhance functionality and harmonize data formats. Utilizing advanced tools like Azure, MuleSoft Anypoint, Funnel, Jitterbit, and Dell Boomi, we optimize costs while delivering efficient API connections that improve operational workflows and ensure consistent data synchronization.',
    },
    {
      image: enterpriseApplication,
      heading: 'Enterprise Application Integration',
      para: 'Our quality assurance and testing methodologies ensure high-performing applications through detailed issue diagnosis, rigorous testing, and ongoing performance evaluations. Using advanced tools and techniques, we deliver seamless processes and reliable application performance.',
    },
    {
        image: applicationMigration,
        heading: 'Application Migration Solutions',
        para: 'With extensive knowledge of data adoption processes and platforms, we assist businesses in managing cloud applications post-migration, enabling scalability and optimized performance.',
    },
    {
        image: storageMigration,
        heading: 'Storage Migration Services',
        para: 'Our storage migration services are designed to enhance performance and deliver cost-effective scalability. By maintaining consistent data access paths and links, we ensure a smooth transition to faster and more efficient storage solutions.',
    }
  ]

const AISolutions = [
    {
        image: dataMapping,
        heading: 'AI Data Mapping and Assessment',
        para: 'Stamens experts leverage AI-powered Machine Learning algorithms to automate the identification, creation, and analysis of accurate data mappings. This approach minimizes manual errors, accelerates synchronization, and ensures efficient and accurate data transfers.',
      },
      {
        image: anomalyDetection,
        heading: 'AI Anomaly Detection',
        para: 'Our team utilizes AI to enhance anomaly detection, employing Machine Learning to identify irregularities, issue breach alerts, and protect against fraud. With AI-driven security tools, we conduct ongoing risk assessments and monitor migration environments to ensure data safety and reliability.',
      },
      {
        image: monitoring,
        heading: 'Real-Time Monitoring and Reporting',
        para: "Stamens integrates AI-optimized monitoring solutions to oversee data transformations, enabling prompt resolution of issues, preserving data integrity, and reducing downtime. These capabilities offer real-time insights, comprehensive reporting, and a smooth migration process without unexpected disruptions.",
      },
      {
        image: dataValidation,
        heading: 'Automated Data Validation',
        para: 'By integrating AI technology, we automate the data validation process to ensure transformations meet established standards. Our advanced AI tools perform thorough validation checks, enhancing data quality, expediting migrations, and minimizing potential setbacks.',
      }
  ]

  const DataMigrationSupport = [
    {
        image: preMigration,
        heading: 'Pre-Migration Assessment',
        para: 'We carry out a thorough evaluation of your existing systems, data quality, and migration needs before initiating the migration process. This involves identifying potential risks, evaluating system compatibility, and crafting a comprehensive migration plan to ensure a smooth and risk-free transition.',
      },
      {
        image: postMigration,
        heading: 'Post-Migration Validation and Testing',
        para: 'After migration, we conduct detailed validation and testing to confirm data accuracy and completeness. This includes functional testing of the target system, performance evaluations, and verification of data integrity. We also provide ongoing support to address any post-migration challenges and maintain system stability.',
      },
      {
        image: migrationTool,
        heading: 'Migration Tool Setup and Configuration',
        para: "Our experts assist in the installation and configuration of migration tools customized to your specific requirements, ensuring seamless integration with your existing infrastructure. We also deploy monitoring tools to track migration progress, identify bottlenecks, ensure data quality, and facilitate timely completion.",
      },
      {
        image: transformationDesign,
        heading: 'Data Transformation Design and Debugging',
        para: 'We develop and implement robust data mapping and transformation strategies to align source data with target schema requirements. Our team refines transformation processes, addressing issues like mismatched formats, missing data, and other challenges to ensure accurate and consistent data integration.',
      },
      {
        image: transformationDesign,
        heading: 'Data Transformation Debugging',
        para: "Our specialists focus on optimizing data transformation by debugging and resolving issues such as formatting mismatches, data loss, or errors during the process. By leveraging advanced troubleshooting methods, we ensure a seamless flow of data from the source to the target system.",
      },
      {
        image: dataLoading,
        heading: 'Data Loading Support',
        para: 'We provide comprehensive assistance for loading transformed data into the target system while optimizing performance and minimizing downtime. This includes techniques such as batch processing, parallel loading, and real-time data streaming. Additionally, we offer post-migration support to address any issues and ensure long-term success.',
      }
  ]



function DataMigration() {
  return (
    <>
      <Helmet>
   <title>Data Migration| Services | Stamens Software-Services</title>
   </Helmet>

   <Banner details={bannerDetails}/>

   <section class="our-fetaures-datamigration">
            <div class="container">
                <h3 class="head"><span>Hire</span>
                    Experienced Data Migration Experts
                </h3>
                <p class="tagline">Stamens stands as a trusted leader in software development, specializing in seamless data migration services. Backed by years of expertise, our skilled professionals efficiently handle the migration of databases, servers, user accounts, agent jobs, and SSIS packages while ensuring minimal downtime. Our tailored solutions empower businesses to transition from outdated systems to modern platforms, boosting efficiency and reducing operational costs. Whether you're moving to the cloud or upgrading your infrastructure, Stamens delivers customized data migration strategies designed to meet your specific requirements and guarantee success. Experience the Stamens difference—innovation, reliability, and excellence in every solution.</p>
                <div class="row justify-content-center gy-4">
                {MigrationExperts.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} childClassA={'small-head'} />
            ))}      
                </div>
            </div>
        </section>

        <section class="work-process-datamigration">
            <div class="container">
                <h3 class="head text-center">Artificial Intelligence Solutions for Data Migration</h3>
                <p class="tagline text-center">Stamens harnesses the power of Artificial Intelligence to simplify workflows and facilitate smooth data transitions to cloud-based or existing platforms. Our AI-driven data migration solutions automate key processes, including data management, report generation, and project tracking, empowering businesses with data-driven insights and enhancing their decision-making and operational intelligence.</p>
                <div class="row gy-4">
                {AISolutions.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}    
                </div>
            </div>
        </section>

        <LetsCode />

        <section class="application-solutions-datamigration">
            <div class="container">
                <h3 class="head">Transform Your Applications with AI-Driven Solutions</h3>
                <p class="tagline">Redefine your applications with our advanced AI-powered solutions. Embrace the next generation of application support, where cutting-edge innovation enhances performance and elevates user satisfaction to new heights.</p>
                <div class="row gy-4">
                {DataMigrationSupport.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}     
                </div>
            </div>
        </section>

        <section class="our-company-datamigration">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={industry15} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={sourceCode} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={microsoftAzure} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={response24} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={certifiedDevelopers} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={projectManager} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={clients} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={tightDeadlines} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section class="app-mobile-datamigration">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="col-md-4">
                        <img src={appMobile} alt="app-mobile" class="img-fluid" />
                    </div>
                    <div class="col-md-8">
                        <h3 class="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>  
                        <Link to="/contact" class="hire-btn">Hire Us Now</Link>          
                    </div>
                </div>
            </div>
        </section>

        <section class="trusted-datamigration">
            <div class="container">
                <div class="text-center">
                    <h4 class="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p class="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
                </div>
                <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {client.map((client, index) => (
                <SwiperSlide key={index} className='swiper-slide'>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
            </div>
        </section>  

   <Footer />
    </>
  )
}

export default DataMigration;
