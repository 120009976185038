import React from "react";

function Sidenavbar({ items, activeId }) {
  const activeItem = items.find(item => item.id === activeId);
  const isActiveBlue = activeItem && activeItem.bgColor === 'blue';
  const isActiveWhite = activeItem && activeItem.bgColor === 'white';

  return (
    <div id="list-example" className={`list-group pp-pos ${isActiveBlue ? 'active-blue' : ''} ${isActiveWhite ? 'active-white' : ''}`}>
      {items.map((item, index) => (
        <a
          key={index}
          href={`#${item.id}`}
          className={`list ${window.location.hash === `#${item.id}` ? 'active' : ''} ${isActiveBlue ? 'active-blue' : ''} ${isActiveWhite ? 'active-white' : ''}`}
        >
          <span style={{ color: isActiveBlue ? '#fff' : '#0f3285' }}>{item.text}</span>
        </a>
      ))}
    </div>
  );
}

export default Sidenavbar;
