import React from 'react';
import { Link } from 'react-router-dom';

function Process({ spanContent, heading, subheading, processItems = [], parentClass,childClass, childClassA }) {
    if (processItems.length === 0) return null;
    return (
      <section className={parentClass}>
        <div className="container">
          <h3 className="head text-center">
            <span>{spanContent}</span>
            {heading}
          </h3>
          <p className="text-center fs-5 pb-4">{subheading}</p>
          <div className={childClass?childClass:"process"}>
            {processItems.map((item, index) => (
              item.link ? 
              <Link key={index} className='point-link' to={item.link}>
              <div className={childClassA? childClassA : 'point'}  key={index} >
                <div className="num">{item.num}</div>
                <div className="head">
                  <img src={item.image} alt={item.contentheading} className="img-fluid" />
                  <h4 className="name">{item.contentheading}</h4>
                </div>
                <p>{item.description}</p>
              </div>
              </Link>
              : 
              <div className={childClassA? childClassA : 'point'} key={index} >
                <div className="num">{item.num}</div>
                <div className="head">
                  <img src={item.image} alt={item.contentheading} className="img-fluid" />
                  <h4 className="name">{item.contentheading}</h4>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

export default Process;
