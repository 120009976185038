import React from 'react';
import './siteMap.css';
import Banner from '../components/banner';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import SiteMapComponent from '../components/siteMapComponent';

const bannerDetails =
  {
      title: 'Our Sitemap',
      subTitle: 'Stamens Sitemap'
  }

function SiteMap() {
  return (
    <>

    <Helmet>
    <title> SiteMap | Stamens Software - A leading IT Company</title>
    </Helmet>

    <Banner details={bannerDetails} />

    <SiteMapComponent />

    <Footer />

    </>
  )
}

export default SiteMap;
