import React from 'react';
import { Link } from 'react-router-dom';

function IconCard({cardProps, parentClass, childClassA,childClassB, dataAos, dataAosEasing, dataAosDuration, parentChildClass, buttonClass, paraClass, iconClass}) {

    return (
        <div className={parentClass ? parentClass : 'col-lg-4 col-md-6'}>
            <div className={parentChildClass ? parentChildClass : "part"} data-aos={dataAos ? dataAos : null} data-aos-easing={dataAosEasing? dataAosEasing: null} data-aos-duration={dataAosDuration ? dataAosDuration : null}>
            {childClassB ? (
                <>
                    <div className={childClassA ? childClassA : "icon"}>
                        <i className={iconClass ? iconClass : "bi bi-database"}></i>
                        </div>
                        <div className={childClassB}>
                            <p className={paraClass ? paraClass : "head"}>{cardProps.title}</p>
                            <p>{cardProps.description}</p>
                            {cardProps.button ? 
                                    <a href="#">Read More <span>{'>>'}</span></a>
                                : null}
                        </div>
                        </>
                ) : (
                    <div className={childClassA ? childClassA : "icon"}>
                        <i className={iconClass ? iconClass : "bi bi-database"}></i>
                        <p className={paraClass ? paraClass : "head"}>{cardProps.title}</p>
                    <p>{cardProps.description}</p>
                    {cardProps.button ? 
                                <a href="#">Read More <span>{'>>'}</span></a>
                            : null}
                    </div>

                )}
                </div>
        </div>
    );
}

export default IconCard;
