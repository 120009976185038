import React from 'react'
import { Link } from 'react-router-dom';

function LetsCode() {
  return (
    <section className="success-story">
    <div className="container">
        <h3 className="head"><span>Let's Code</span> Your Success Story Together</h3>
        <p className="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the best design and development services for your ultimate website</p>
        <div className="btn-group">
            <Link to="/contact"><i className="fa fa-clipboard-question"></i> Ask a Query</Link>
            <a href="tel:+911203500848"><i className="fa fa-phone" aria-hidden="true"></i> Call Now</a>
        </div>
    </div>
</section>
  )
}

export default LetsCode;
