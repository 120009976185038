import React, { useEffect } from "react";
import './hireServices.css';
import Footer from "../components/footer";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination } from "swiper/modules";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
import Banner from "../components/banner";
const clients = [
    `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
    `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
    `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
  ];
const HireDeveloper = `${process.env.PUBLIC_URL}/images/hire-developer.png`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const softwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/software-development.svg`;
const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const client = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'Hire Dedicated Team',
      description: 'Partner with our dedicated team to unlock tailored software solutions that drive your success. Your vision, our expertise.',
      buttonText : 'Hire',
      button: true
  }

function HireDedicatedTeam(){

    useEffect(() => {
        const maxWidth = 991;
        
        Aos.init({
          duration: 1000,
          easing: 'linear',
          mirror: false,
          once: true,
          disable: () => {
            return window.innerWidth < maxWidth;
          },
        });
    
        const interval = setInterval(() => {
          Aos.refresh();
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);

    return(
        <>
        <Helmet>
    <title>Hire Dedicated Team | Stamens Software</title>
    </Helmet>

    <Banner details={bannerDetails} />

        <section className="sucess-story-hire">
            <div className="container">
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-5 col-md-5">
                        <div className="content">
                            <h2 className="head">
                                Success Stories of Brands We are Dedicated
                            </h2>
                            <p>Many top-notch brands have chosen us as their digital transformation partner.</p>
                        </div>
                    </div>
                  
                   <div className="col-lg-7 col-md-7 pt-5" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">
                   <div className="col-lg-12">
            <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {clients.map((client, index) => (
                <SwiperSlide key={index}>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          </div>
                </div>
            </div>
        </section>

        <section className="work-process-hire">
            <div className="container">
                <div className="image-text">
                    <div className="image">
                        <img src={HireDeveloper} alt="hire developers" className="img-fluid" />
                    </div>
                    <div className="text">
                        <h2 className="head"><span>Welcome to Stamens</span>
                            Hire dedicated developers
                        </h2>
                        <p>If the lack of the right talent is delaying your project delivery, hiring our dedicated teams can be the optimum solution for you. Our dedicated teams will exclusively work on your projects while sitting in our well-established development center.</p>
                        <a className="connect-btn" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Lets Connect</a>
                    </div>
                </div>
            </div>
        </section>

        <section className="our-company-sec-hire">
            <div className="container">
                <h4 className="head"><span>Why Stamens Software</span>Dedicated development team</h4>
                <p className="text-center mb-4">Stamens has teams of experienced web designers, developers, project managers, IT consultants, network managers, business analysts, quality analysts, and internet marketing professionals. Experts with the desired skill set can be made available at your disposal at a fixed monthly cost.</p>
                <div className="row m-0 p-0">
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 m-0 p-0">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> 

        <section className="connect-hire">
            <div className="container">
                <h3 className="head"><span>Let's Go to</span> Hiring Process</h3>
                <p className="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Let us know about your resource requirements. We will send you the expert details. We promise for punctuality and loyalty.</p>
                <div className="process-points">
                    <div className="point">
                        <div className="icon">
                            <i className="bi bi-person-workspace"></i>
                        </div>
                        <p>Our <span>analysts</span> will evaluate your project requirement</p>
                    </div>
                    <div className="point">
                        <div className="icon">
                            <i className="bi bi-person-workspace"></i>
                        </div>
                        <p>Our <span>analysts</span> will Assess Your Needs</p>
                    </div>
                    <div className="point">
                        <div className="icon">
                            <i className="bi bi-person-workspace"></i>
                        </div>
                        <p>Our <span>analysts</span> will Tailored Solutions Just for You</p>
                    </div>
                    <div className="point">
                        <div className="icon">
                            <i className="bi bi-person-workspace"></i>
                        </div>
                        <p>Our <span>analysts</span> will evaluate your project requirement</p>
                    </div>
                    <div className="point">
                        <div className="icon">
                            <i className="bi bi-person-workspace"></i>
                        </div>
                        <p>Our <span>analysts</span> will Expert Evaluation Process</p>
                    </div>
                </div>
                <a href="#">Hire Now</a>
            </div>
        </section>

        <section className="technologie">
            <div className="container">
                <h3 className="head">
                    <span>Hire skills </span>
                    we offer dedicated team to hire with various skills
                </h3>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="part">
                            <img src={softwareDevelopment} alt="Icon" className="img-fluid" />
                            <h4>Hire Web Developer</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="part">
                            <img src={softwareDevelopment} alt="Icon" className="img-fluid" />
                            <h4>Hire Mobile Developer</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="part">
                            <img src={softwareDevelopment} alt="Icon" className="img-fluid" />
                            <h4>Hire Custom Design Developer</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="part">
                            <img src={softwareDevelopment} alt="Icon" className="img-fluid" />
                            <h4>Hire Database Developer</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="part">
                            <img src={softwareDevelopment} alt="Icon" className="img-fluid" />
                            <h4>Hire Quality Analyst</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="part">
                            <img src={softwareDevelopment} alt="Icon" className="img-fluid" />
                            <h4>Hire Digital Marketing</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="keyword-hire">
            <div className="container">
                <h3 className="head">
                    <span>Hire </span>
                    Hire developers Skills
                </h3>
                <ul className="Skill-point">
                    <li>Mobile App</li>
                    <li>Web</li>
                    <li>Data Science</li>
                    <li>Database</li>
                    <li>ASP.NET</li>
                    <li>Quality Analyst</li>
                    <li>UI/UX</li>
                    <li>Open Source</li>
                </ul>
                <ul className="keywords-point">
                    <li>#Xcode</li>
                    <li>#iOS Simulators</li>
                    <li>#TestFlight</li>
                    <li>#Instrument</li>
                    <li>#Objective C</li>
                    <li>#Swift</li>
                    <li>#SwiftUI</li>
                    <li>#Cocoa Touch</li>
                    <li>#Core Data</li>
                    <li>#Sqlite</li>
                    <li>#3D Touch</li>
                    <li>#CallKit</li>
                    <li>#HealthKit</li>
                    <li>#AVFoundation</li>
                    <li>#CloudKit</li>
                    <li>#SpriteKit</li>
                    <li>#MultipeerConnectivity</li>
                    <li>#AdSupport</li>
                    <li>#Core Bluetooth</li>
                    <li>#Android Studio</li>
                    <li>#Eclipse</li>
                    <li>#Android SDK</li>
                    <li>#JVM</li>
                    <li>#AVD</li>
                    <li>#ADB</li>
                    <li>#AndroidX</li>
                    <li>#Retrofit</li>
                    <li>#Sqlite</li>
                    <li>#Glide</li>
                    <li>#Java</li>
                    <li>#Kotlin</li>
                </ul>
            </div>
        </section>

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>          
                    </div>
                </div>
            </div>
        </section>

<Footer />        

    </>
    )
}

export default HireDedicatedTeam;